.s-alert-box {
  min-width: 250px;
  max-width: 400px;
  z-index: 9999;
}

.s-alert-close::before, .s-alert-close::after {
  width: 2px;
}

.fullscreen.fullscreen-enabled {
  overflow: auto;
}